import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import { CssBaseline, Stack } from "@material-ui/core"
import logo from "../images/logo.svg"
import Contact from "../components/Contact"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const CareerPage = () => {
  const [activeJob, setActiveJob] = useState()

  const {
    allMarkdownRemark: { edges: jobs },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "job" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              category
              jobType
              location
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (jobs && !activeJob) {
      setActiveJob(jobs[0])
    }
  }, [jobs])

  return (
    <main>
      <CssBaseline />
      <Wrapper>
        <Container className="container" maxWidth={"xl"}>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Link to="/">
              <img height={39} src={logo} width={105} />
            </Link>
            <div
              style={{
                fontFamily: "Raleway",
                fontSize: "1.8rem",
                // textTransform: "uppercase",
                color: "#555",
                fontWeight: 200,
              }}
            >
              Jobs
            </div>
          </Stack>
          <Stack direction="row" className={"jobs"}>
            <div className={"sidebar"}>
              {jobs.map(({ node: job }) => (
                <SideBarItem
                  selected={job.id === activeJob?.id}
                  onClick={() => setActiveJob(job)}
                >
                  {console.log(job.id === activeJob?.id)}
                  <div className="title">{job.frontmatter.title}</div>
                  <div className="category">{job.frontmatter.category}</div>
                  <Stack direction="row" spacing={2}>
                    <div className="location">{job.frontmatter.location}</div>
                    <div className="jobType">{job.frontmatter.jobType}</div>
                  </Stack>
                </SideBarItem>
              ))}
            </div>

            <div
              className={"content"}
              dangerouslySetInnerHTML={{ __html: activeJob?.html || "" }}
            />
          </Stack>
        </Container>
      </Wrapper>
      <Contact />
    </main>
  )
}

const SideBarItem = styled.div<{ selected?: boolean }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;
  padding: 10px 30px;
  min-height: 120px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  font-family: "Poppins";
  color: #222;
  font-weight: 300;
  cursor: pointer;
  /* border-right: ${({ selected }) =>
    selected ? "1px solid #db5892" : "1px solid transparent"}; */
  @media (max-width: 1366px) {
    font-size: 0.9rem;
  }
  transition: 0.1s ease-in;
  & .title {
    font-weight: 500;
    font-size: 1.1rem;
    /* color: #333; */
    color: ${({ selected }) => (selected ? "#db5892" : "#333")};
    margin-bottom: 8px;
    transition: 0.1s ease-in;
    @media (max-width: 1366px) {
      font-size: 1.1rem;
    }
  }

  & > div {
    color: ${({ selected }) => (selected ? "#000" : "#444")};
  }

  .category {
  }
`
const Wrapper = styled(Grid)`
  background: linear-gradient(180deg, #dae6ff 0%, #edf3ff 100%, #edf3ff 100%);
  scroll-snap-align: start;

  position: relative;
  height: 100vh;
  overflow: hidden;

  .container {
    padding: 50px 0 20px;
    height: 85%;
    @media (max-width: 1366px) {
      max-width: 1200px;
    }
  }

  .jobs {
    height: 100%;
    margin-top: 30px;
    & > div {
      background: white;
      box-shadow: -10px -10px 35px rgba(0, 0, 0, 0.075);
    }
    .sidebar {
      height: 100%;

      margin-right: 25px;
      overflow-y: auto;
      flex: 3;
      border-bottom: 10px solid #db5892;
      scroll-snap-type: y mandatory;
    }
    .content {
      flex: 7;
      overflow-y: auto;
      padding: 20px;
      border-bottom: 10px solid #f2c775;
      line-height: 1.7em;
      font-family: "Poppins";
      strong {
        font-weight: 600;

        line-height: 3em;
      }
    }
  }
`

export default CareerPage
